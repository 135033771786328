import React from "react";
import BlogShareFloatingMenu from "../components/BlogShareFloatingMenu";
import moment from "moment";
import "./BlogsCmsStyle.scss";
import { useEffect, useState, useRef } from "react";
import AboutAuthor from "./sections/AboutAuthor";
import { title } from "process";
import config from "../../../util/config";
import { useLocation, useParams } from "react-router-dom";

import {
  faFacebook,
  faFacebookF,
  faLinkedin,
  faLinkedinIn,
  faXTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share";
interface BlogProps {
  blogResponse: any;
  onStickyChange: (isSticky: boolean) => void;
  onScrollProgress: (progress: number) => void;
  breadCrumb: Breadcrumb;
}
interface Breadcrumb {
  newsTitle: { title: string; slug: string };
  newsSubTopic: { title: string; slug: string };
  newsTopic: { title: string; slug: string };
  home: { title: string; slug: string };
}
interface HeaderProps {
  titleInfo: any;
  headerRef: React.RefObject<HTMLDivElement>;
  breadCrumb: Breadcrumb;
}
type SMLinkProps = {
  link: string;
};

const SMLink: React.FC<SMLinkProps> = ({ link }) => {
  return (
    <ul className="flex flex-row gap-4 p-2">
      <li
        className="flex flex-row items-center justify-center gap-2 group"
        data-value="facebook"
      >
        <FacebookShareButton
          url={`${config.apiUrl}${link}`}
          className="flex flex-row items-center justify-center gap-2 p-1"
        >
          <FontAwesomeIcon
            icon={faFacebookF}
            className="text-xl text-[#173CBA]"
          />
        </FacebookShareButton>
      </li>
      <li className="flex items-center group" data-value="facebook">
        <LinkedinShareButton
          // url={"https://linkedin.com/"}
          url={`${config.apiUrl}${link}`}
          className="flex flex-row items-center justify-center gap-2 p-1"
        >
          <FontAwesomeIcon
            icon={faLinkedinIn}
            className="text-xl text-[#173CBA] "
          />
        </LinkedinShareButton>
      </li>
      <li className="flex items-center group" data-value="linkedin">
        <TwitterShareButton
          // url={"https://twitter.com/"}
          url={`${config.apiUrl}${link}`}
          className="flex flex-row items-center justify-center gap-2 p-1"
        >
          <FontAwesomeIcon
            icon={faXTwitter}
            className="text-xl text-[#173CBA] "
          />
        </TwitterShareButton>
      </li>
      <li className="flex items-center group" data-value="linkedin">
        <WhatsappShareButton
          // url={"https://www.whatsapp.com/"}
          url={`${config.apiUrl}${link}`}
          className="flex flex-row items-center justify-center gap-2 p-1"
        >
          <FontAwesomeIcon
            icon={faWhatsapp}
            className="text-xl text-[#173CBA] "
          />
        </WhatsappShareButton>
      </li>
      <li className="flex items-center group" data-value="linkedin">
        {/* <EmailShareButton
          url={`${config.apiUrl}${link}`}
          body={`${config.apiUrl}${link}`}
          subject={"Check this blog from College Mentor!"}
          className="flex flex-row items-center justify-center gap-2 p-1 w-[50px]"
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            className="text-xl text-[#173CBA] "
          />
        </EmailShareButton> */}

        {/* <a href="mailto:?subject=Check this out&body=Here's an amazing website: https://yourwebsite.com">
            <FontAwesomeIcon icon={faEnvelope} className="text-sm text-white" />{" "}
            <span className="text-xs text-white">E-mail</span>
          </a> */}

        <a
          href={`mailto:admin@collegmentor.com?subject=Check this blog from College Mentor!&body=${config.apiUrl}${link}`}
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            className="text-xl text-[#173CBA] "
          />{" "}
          <span className="text-xs text-white">E-mail</span>
        </a>
      </li>
    </ul>
  );
};

const Header: React.FC<HeaderProps> = ({
  titleInfo,
  headerRef,
  breadCrumb,
}) => {
  const location = useLocation();
  let { topicSlug } = useParams();
  const [link, setLink] = useState(
    `/blogs/${
      breadCrumb?.newsSubTopic?.slug ||
      breadCrumb?.newsTopic?.slug ||
      "category"
    }/${breadCrumb?.newsTitle?.slug}`
  );
  const formattedDate = (date: any) =>
    moment(date).format("MMM D, YYYY | h:mm A");
  return (
    <div ref={headerRef} className="flex flex-col gap-1">
      <h1 className="text-5xl font-bold">{titleInfo?.title}</h1>
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col">
          <AboutAuthor authorDetails={titleInfo} />
          {/* <div className="items-center mr-2 text-xs capitalize xl:text-sm mb-10px">
        <span className="mr-1 leading-4 xl:leading-18px">By</span>
        <a className="mr-1 font-bold hover:underline xl:leading-18px leading-2">
          {titleInfo?.createdUser?.firstName},
        </a>
        <span className="leading-2">Technical Writer</span>
      </div>

      
        */}
          <div className="flex flex-row gap-1">
            <span className="flex text-sm">
              <p className="mr-1 font-semibold">Updated on: </p>{" "}
              {formattedDate(titleInfo?.updatedDate)}
            </span>
          </div>
        </div>
        <div className="md:ml-4 md:mt-7">
          <SMLink link={link} />
        </div>
      </div>
    </div>
  );
};

const Blog: React.FC<BlogProps> = ({
  blogResponse,
  onStickyChange,
  onScrollProgress,
  breadCrumb,
}) => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const documentHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrollPercent = (scrollTop / documentHeight) * 100;
    setScrollProgress(scrollPercent);
    onScrollProgress(scrollPercent);

    if (headerRef.current) {
      const headerTop = headerRef.current.getBoundingClientRect().top;
      const isHeaderSticky = headerTop <= 0;
      setIsSticky(isHeaderSticky);
      onStickyChange(isHeaderSticky);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="">
        <Header
          titleInfo={blogResponse}
          headerRef={headerRef}
          breadCrumb={breadCrumb}
        />
        {blogResponse?.newsCategory?.name == "news" && (
          <img
            src={blogResponse?.imageUrl}
            className="w-full py-6"
            alt="thumbnail image"
          />
        )}
      </div>
      <div className="mt-2">
        <style>{`
          .blog-content{
            text-align:justify;
          }

          .blog-content p {
            margin: 1em 0;
            line-height: 1.5;
            color: #333; /* Adjust text color */
          }

          .blog-content ul {
            list-style-type: disc;
            margin-left: 20px;
          }

          .blog-content ol {
            margin-left: 20px;
            list-style-type: number;
          }

          .blog-content img {
            max-width: 100%; /* Makes images responsive */
            height: auto;
          }

          .blog-content table, .blog-content th, .blog-content td {
            border: 1px solid black !important;
            padding: 0.5rem;
          }

          @media (max-width: 768px) { 
            .blog-content h1, .blog-content h2, .blog-content h3, .blog-content h4, .blog-content h5, .blog-content h6, p.heading10, p.heading20, p.heading30 {
              text-align: left !important;
            }
          }
        `}</style>
        <div
          className="blog-content"
          dangerouslySetInnerHTML={{
            __html: blogResponse?.content,
          }}
        />
      </div>
    </div>
  );
};

export { Header };

export default Blog;
